import React from 'react';
import slack from '../../images/integrations/slack.svg';
import IntegrationBot from '../../components/integrations/indegrations';

const SlackPage = () => (
  <IntegrationBot
    botLogo={slack}
    botName={'Slack'}
  />
);

export default SlackPage;
